
import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import SwitchSelect from '../../../Components/Components/SwitchSelect';
import Toggle from '../../../Components/Components/Toggle';

import {
  toggleFilterParam, toggleNewGameParam, setNewGameBet, setNewMinGamesFunc, setNewGameSpeed,
} from '../../../../../../actions/userSettings';
import config from '../../../../../../constants/config';
import { FONT_MODE } from '../../../../../../constants/constants';


/*
const GameTypeMap = [
  { name: 'P', value: 'P' },
  { name: 'PM', value: 'PM' },
  { name: 'G', value: 'G' },
  { name: 'MG', value: 'MG' },
  { name: 'Privāta', value: 'Privāta' },
];
*/

const GameBetMap = [
  { name: '1:10', value: '1:10' },
  { name: '1:25', value: '1:25' },
  { name: '1:50', value: '1:50' },
  { name: '1:100', value: '1:100' },
  { name: '1:250', value: '1:250' },
  { name: '1:500', value: '1:500' },
  { name: '1:1000', value: '1:1000' },
  { name: '1:5000', value: '1:5000' },
  { name: '1:10000', value: '1:10000' },
];

const GameBetMapLowLevel = [
  // { name: '1:1', value: '1:1' },
  // { name: '1:5', value: '1:5' },
  { name: '1:10', value: '1:10' },
  { name: '1:25', value: '1:25' },
  { name: '1:50', value: '1:50' },
  { name: '1:100', value: '1:100' },
  { name: '1:250', value: '1:250' },
  { name: '1:500', value: '1:500' },
  { name: '1:1000', value: '1:1000' },
  { name: '1:5000', value: '1:5000' },
  { name: '1:10000', value: '1:10000' },
];
//{ name: t('newGame.unlimitedGame'), value: 'unlimited' },
const GameSpeedMap = [
  { name: 'unlimited', value: 'unlimited' },
  { name: 'slow', value: 'parasta' },
  { name: 'atras', value: 'atra' },
  { name: 'lightnings', value: 'lightning' },
];

const MinGamesMap = [
  { name: 'noLimit', value: 1 },
  { name: '3', value: 3 },
  { name: '5', value: 5 },
  { name: '10', value: 10 },
  { name: '15', value: 15 },
  { name: '20', value: 20 },
  { name: '25', value: 25 },
];

class Header extends React.Component {
  static propTypes = {
    uid: PropTypes.string,
    t: PropTypes.func.isRequired,
    userSettings: PropTypes.shape(),
    toggleNewGameParameter: PropTypes.func.isRequired,
    setNewBet: PropTypes.func.isRequired,
    setNewSpeed: PropTypes.func.isRequired,
    setNewMinGames: PropTypes.func.isRequired,
    closeFilter: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
  }

  static defaultProps = {
    uid: null,
    userSettings: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      //  scrollPos: 0,
      gFlag: false,
      fourPRoomFlag: false,
      speedFlag: 'parasta',
      betFlag: '1:10',
      minGames: 1,
      mazaFlag: false,
      privateRoomFlag: false,
      proFlag: false,
      filters: {
        speed: [],
        bet: [],
        minGames: [],
        fourPRoom: [],
        gameType: [],
        maza: false,
        privateRoom: false,
        pro: false,
        friendsRoom: false,
      },
    };
  }

  componentDidMount() {
    const { userSettings } = this.props;

    // const { parasta, fourPRoom, speed, bet, minGames, maza, privateRoom, pro } = userSettings;
    // this.setState({
    //   gFlag: parasta,
    //   fourPRoomFlag: fourPRoom,
    //   speedFlag: speed,
    //   betFlag: bet,
    //   minGamesFlag: minGames,
    //   mazaFlag: maza,
    //   privateRoomFlag: privateRoom,
    //   proFlag: pro,
    // });
    const { roomsFilter } = userSettings;

    console.log('componentDidMount', userSettings);

    if (roomsFilter) {
      this.setState({ filters: roomsFilter });
    } else {
      this.setState({
        filters: {
          speed: [],
          bet: [],
          minGames: [],
          fourPRoom: [],
          gameType: [],
          maza: false,
          privateRoom: false,
          pro: false,
          friendsRoom: false,
        },
      });
    }
  }

  openCreate = () => {
    this.setState({ createModalOpen: true });
  }

  closeCreate = () => {
    this.setState({ createModalOpen: false });
  }

  selectFilter = (type, value) => {
    const { filters } = this.state;

    if (type === 'bet') {
      const { bet } = filters;

      if (value === 'all') {
        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            bet: [],
          },
        }));
      } else {
        let newVal = [...bet];
        const index = newVal.indexOf(value);

        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }

        if (newVal.length === GameBetMap.length) {
          newVal = [];
        }

        console.log('newVal', { index, newVal });

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            bet: newVal,
          },
        }));

        this.setState({ bet: newVal });
      }
    } else if (type === 'speed') {
      const { speed } = filters;

      if (value === 'all') {
        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            speed: [],
          },
        }));
      } else {
        let newVal = [...speed];
        const index = newVal.indexOf(value);

        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }

        console.log('newVal', { GameSpeedMap, newVal });

        if (newVal.length === GameSpeedMap.length) {
          newVal = [];
        }

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            speed: newVal,
          },
        }));
      }
    } else if (type === 'minGames') {
      const { minGames } = filters;

      if (value === 'all') {
        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            minGames: [],
          },
        }));
      } else {
        let newVal = [...minGames];
        const index = newVal.indexOf(value);

        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }

        if (newVal.length === MinGamesMap.length) {
          newVal = [];
        }

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            minGames: newVal,
          },
        }));
      }
    } else if (type === 'fourPRoom') {
      const { fourPRoom } = filters;

      if (value === 'all') {
        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            fourPRoom: [],
            studentRoom: true,
          },
        }));
      } else {
        let newVal = [...fourPRoom];
        console.log('fourPRoom newVal', newVal);

        const index = newVal.indexOf(value);

        console.log('index', index);

        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }

        if (newVal.length === 3) {
          newVal = [];
        }

        console.log('newVal', newVal);

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            fourPRoom: newVal,
            studentRoom: newVal.length === 0 || newVal.includes(2),
          },
        }));
      }
    } else if (type === 'gameType') {
      const { gameType } = filters;

      if (value === 'all') {
        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            gameType: [],
          },
        }));
      } else {
        let newVal = [...gameType];
        const index = newVal.indexOf(value);

        if (index === -1) {
          newVal.push(value);
        } else {
          newVal.splice(index, 1);
        }

        if (newVal.length === 2) {
          newVal = [];
        }

        this.setState(prevState => ({
          filters: {
            ...prevState.filters,
            gameType: newVal,
          },
        }));
      }
    }
  }

  toggleSmall = () => {
    const { filters } = this.state;
    const { maza } = filters;
    const mazaFlag = maza;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        maza: !mazaFlag
      },
    }));
  };

  togglePrivate = () => {
    const { filters } = this.state;
    const { privateRoom } = filters;
    const privateRoomFlag = privateRoom;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        privateRoom: !privateRoomFlag,
      },
    }));
  };

  toggleStudent = () => {
    const { filters } = this.state;
    const { studentRoom } = filters;
    const studentRoomFlag = studentRoom;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        studentRoom: !studentRoomFlag,
        fourPRoom: [],
      },
    }));
  };

  togglePro = () => {
    const { filters } = this.state;
    const { pro } = filters;
    const proFlag = pro;
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        pro: !proFlag,
      },
    }));
  };

  toggleFriendsRoom = () => {
    const { filters } = this.state;
    const { friendsRoom } = filters;
    const friendsRoomFlag = friendsRoom;

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        friendsRoom: !friendsRoomFlag,
      },
    }));
  }

  saveFilters = () => {
    const { toggleFilterParameter, uid, userSettings, setNewBet, setNewMinGames, setNewSpeed, closeFilter } = this.props;
    const { filters } = this.state;

    toggleFilterParameter(filters, uid);

    closeFilter();
  };

  render() {
    const {
      t,
      gameTypeFilterMap,
      gameTypeFilterOpen,
      typeFilter,
      gameBetFilterMap,
      gameBetFilterOpen,
      betFilter,
      level,
      toggleGameTypeFilter,
      toggleGameBetFilter,
      filterType,
      filterBet,
      userSettings,
      lvl,
      fontMode,
      devMode,
    } = this.props;

    const { gFlag, fourPRoomFlag, speedFlag, betFlag, minGamesFlag, mazaFlag, privateRoomFlag, proFlag, filters } = this.state;

    const { roomsFilter } = userSettings;

    console.log('userSettings', { userSettings });

    console.log('roomsFilter', { gFlag, filters });

    const isInAppFrame = config.isInAppFrame();
    const switchStyle = isInAppFrame ? {margin: `${fontMode === FONT_MODE.xLarge ? '5px' : fontMode === FONT_MODE.large ? '7px' : '9px'} 2px` } : {};

    return (

      <Row className='filter-modal-body-row'>
        <Col className='filter-modal-body-col'>
          <div className={`filter-modal-body-items-wrapper ${isInAppFrame ? 'filter-modal-body-items-wrapper-in-app-frame' : ''}`}>
            <Row className='filter-modal-body-item-row'>
              <Col className="modal-body-row">
                <Row>
                  <Col sm="12" className="switch-select-label mt-0">{t('common.gameType')}</Col>
                </Row>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''}`}>
                  <div className="display-inline switch-option mr-2">
                    <SwitchSelect innerClassName="room-filter-scroll-wrapper" options={[{ name: 'parastas', value: 'P' }, { name: 'galds', value: 'G' }]} selectedOptions={filters.gameType} selectFunc={value => this.selectFilter('gameType', value)} multiple trans />
                  </div>

                  <div className="display-inline switch-option" style={switchStyle}>
                    <Toggle checked={filters.maza} onChange={this.toggleSmall} text={t('newGame.smallGame')} />
                  </div>
                </div>
                <div className="switch-select-label">
                  {
                    t('newGame.roomType')
                  }
                </div>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''}`}>
                  <div className="display-inline switch-option mr-3">
                    <SwitchSelect
                      innerClassName="room-filter-scroll-wrapper"
                      options={[{ name: 'player4Room', value: 4 }, { name: 'player3Room', value: 3 }]} //, { name: 'studentZole', value: 2 }
                      selectedOptions={filters.fourPRoom.length ? filters.fourPRoom : []}
                      selectFunc={value => this.selectFilter('fourPRoom', value)}
                      multiple
                      trans
                    />
                  </div>
                  {/*
                  {devMode ? (
                    <div className="display-inline switch-option" style={switchStyle}>
                      <Toggle checked={filters.studentRoom} onChange={this.toggleStudent} text={t('newGame.studentZole')} />
                    </div>
                  ) : (null)}
                  */}
                  <div className="display-inline switch-option" style={switchStyle}>
                    <Toggle checked={filters.privateRoom} onChange={this.togglePrivate} text={t('newGame.privateRoom')} />
                  </div>

                  <div className="display-inline switch-option" style={switchStyle}>
                    <Toggle checked={filters.pro} onChange={this.togglePro} text={t('newGame.proGame')} />
                  </div>

                  <div className="display-inline switch-option" style={switchStyle}>
                    <Toggle checked={filters.friendsRoom} onChange={this.toggleFriendsRoom} text={t('newGame.friendsRoom')} />
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row className='filter-modal-body-item-row'>
              <Col className="modal-body-row">
                <div className="switch-select-label">
                  {t('newGame.friendsRoom')}
                </div>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''}`}>
                  <Toggle checked={filters.friendsRoom} onChange={this.toggleFriendsRoom} text={t('newGame.friendsRoom')} />
                </div>
              </Col>
            </Row> */}
            <Row className='filter-modal-body-item-row'>
              <Col className="modal-body-row">
                <div className="switch-select-label">
                  {t('common.speed')}
                </div>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''}`}>
                  <SwitchSelect innerClassName="room-filter-scroll-wrapper" options={GameSpeedMap} selectedOptions={filters.speed} selectFunc={value => this.selectFilter('speed', value)} multiple trans />
                </div>
              </Col>
            </Row>
            <Row className='filter-modal-body-item-row'>
              <Col className="modal-body-row">
                <div className="switch-select-label">
                  {t('common.bet')}
                </div>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''}`}>
                  <SwitchSelect innerClassName="room-filter-scroll-wrapper" options={GameBetMap} typeOption={"bet"} selectedOptions={filters.bet} selectFunc={value => this.selectFilter('bet', value)} multiple trans />
                </div>
              </Col>
            </Row>
            <Row className='filter-modal-body-item-row'>
              <Col className="modal-body-row">
                <div className="switch-select-label">
                  {t('common.minGames')}
                </div>
                <div className={`switch-toggle-column-wrapper ${isInAppFrame ? 'switch-toggle-column-wrapper-in-app-frame' : ''} switch-toggle-column-wrapper-round`}>
                  <SwitchSelect innerClassName="room-filter-scroll-wrapper" options={MinGamesMap} selectedOptions={filters.minGames} selectFunc={value => this.selectFilter('minGames', value)} multiple trans />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              className="layout-highlighted-button"
              color="link"
              onClick={this.saveFilters}
              id="newGame-button"
              onMouseEnter={this.onHover}
              onMouseLeave={this.onHoverLeave}
            >
              {t('common.save')}
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  level: state.member.level || null,
  uid: state.member.uid || null,
  lvl: state.member.level,
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
});

const mapDispatchToProps = {
  toggleFilterParameter: toggleFilterParam,
  toggleNewGameParameter: toggleNewGameParam,
  setNewBet: setNewGameBet,
  setNewSpeed: setNewGameSpeed,
  setNewMinGames: setNewMinGamesFunc,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(Header));
